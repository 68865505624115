import Check from '@mui/icons-material/Check'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { Card, colors, Button, CardContent, Typography } from '@mui/material'
import { useState } from 'react'

interface TokenCardProps {
  title: string
  token: string
}

export const TokenCard = ({ title, token }: TokenCardProps) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(token)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy text:', err)
    }
  }

  return (
    <Card sx={{ width: 1000, borderRadius: '8px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
          textAlign: 'left',
          color: 'white',
          backgroundColor: colors.grey[900]
        }}>
        <Typography variant="h6" style={{ fontSize: '1rem', color: 'white' }}>
          {title}
        </Typography>

        <Button
          size="small"
          startIcon={copied ? <Check /> : <ContentCopy />}
          onClick={handleCopy}
          sx={{
            textTransform: 'none',
            color: 'white',
            '&:focus': { outline: 'none' }
          }}>
          {copied ? 'Copied!' : 'Copy code'}
        </Button>
      </div>
      <CardContent>
        <Typography
          variant="body2"
          textAlign={'justify'}
          sx={{
            color: 'text.secondary',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
          }}>
          {token}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default TokenCard
