import Box from '@mui/material/Box'

import { useAppSelector } from '../../redux/hooks'
import { RootState } from '../../redux/store'
import { TokenCard } from './components/TokenCard'

export const ShowTokenContainer = () => {
  const accessToken = useAppSelector((state: RootState) => state.authenticator.user.token)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '24px',
        gap: '24px'
      }}>
      <TokenCard title="Access Token - 1 hora" token={accessToken} />
    </Box>
  )
}
