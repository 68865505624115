export const methodsOptions = [
  {
    label: 'CREATE',
    value: 'CREATE'
  },
  {
    label: 'UPDATE',
    value: 'UPDATE'
  },
  {
    label: 'DELETE',
    value: 'DELETE'
  },
  {
    label: 'READ',
    value: 'READ'
  }
]

export const entityOptions = [
  {
    label: 'TICKET',
    value: 'ticket'
  },
  {
    label: 'CUSTOMER | UNITÁRIO',
    value: 'customer:{"type":"unit"}'
  },
  {
    label: 'CUSTOMER | GRUPO',
    value: 'customer:{"type":"group"}'
  },
  {
    label: 'TASK',
    value: 'task'
  },
  {
    label: 'DOCUMENT FORMALIZATION TICKET',
    value: 'documentFormalizationTicket'
  },
  {
    label: 'FINANCING REQUEST TICKET',
    value: 'financingRequestTicket'
  }
]

export const validateOptions = [
  {
    label: 'Adicionar campos existentes',
    value: 'ADD'
  },
  {
    label: 'Criar campo com identifier diferente',
    value: 'CHANGE'
  },
  {
    label: 'Ignorar campos com conflito',
    value: 'IGNORE'
  }
]

export const fieldTypeOptions = [
  {
    label: 'address',
    value: 'address'
  },
  {
    label: 'boolean',
    value: 'boolean'
  },
  {
    label: 'currency',
    value: 'currency'
  },
  {
    label: 'date',
    value: 'date'
  },
  {
    label: 'file',
    value: 'file'
  },
  {
    label: 'multiselect',
    value: 'multiselect'
  },
  {
    label: 'numeric',
    value: 'numeric'
  },
  {
    label: 'select',
    value: 'select'
  },
  {
    label: 'text',
    value: 'text'
  },
  {
    label: 'textArea',
    value: 'textArea'
  }
]

export const tagParameters = {
  MANUAL: { label: 'MANUAL', color: 'blue' },
  DESTINY: { label: 'DESTINO', color: 'green' },
  ORIGIN: { label: 'ORIGEM', color: 'purple' },
  NATIVE: { label: 'NATIVO', color: 'gold' }
}

export const entityColors = {
  ticket: 'blue',
  customer: 'red',
  'customer:{"type":"unit"}': 'lime',
  'customer:{"type":"group"}': 'green',
  task: 'purple',
  documentFormalizationTicket: 'gold',
  financingRequestTicket: 'magenta'
}

const fixedConditionOptions = [
  {
    label: 'está presente',
    value: 'present'
  },
  {
    label: 'está ausente',
    value: 'blank'
  }
]

const equalsConditionOptions = [
  {
    label: 'é igual a',
    value: 'equals'
  },
  {
    label: 'é diferente de',
    value: 'not_equals'
  }
]

const textConditionOptions = [
  {
    label: 'tem tamanho igual a',
    value: 'has_size_equals_to'
  }
]

const numericConditionOptions = [
  {
    label: 'é maior que',
    value: 'greater_than'
  },
  {
    label: 'é menor que',
    value: 'less_than'
  }
]

const containsConditionOptions = [
  {
    label: 'contém o valor',
    value: 'includes'
  }
]

export const expressionOperators = (fieldType: string) => {
  if (fieldType == 'boolean' || fieldType == 'date' || fieldType == 'select')
    return fixedConditionOptions.concat(equalsConditionOptions)
  if (fieldType == 'numeric' || fieldType == 'currency')
    return fixedConditionOptions.concat(equalsConditionOptions, numericConditionOptions)
  if (fieldType == 'text' || fieldType == 'textArea')
    return fixedConditionOptions.concat(equalsConditionOptions, textConditionOptions)
  if (fieldType == 'multiselect') return fixedConditionOptions.concat(containsConditionOptions)
  return fixedConditionOptions
}
